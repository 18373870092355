var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w100"},[_c('header'),_c('el-table',{staticStyle:{"width":"90%"},attrs:{"border":"","data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"co_no","label":"订单号"}}),_c('el-table-column',{attrs:{"prop":"u_ali_account","label":"收款账号"}}),_c('el-table-column',{attrs:{"prop":"u_ali_realname","label":"收款人姓名"}}),_c('el-table-column',{attrs:{"prop":"co_price","label":"提现金额"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({
                    color:
                        scope.row.co_status == 0
                            ? '#0882ea'
                            : scope.row.co_status == 1
                                ? '#04d932'
                                : '#ff3e13',
                })},[_vm._v(" "+_vm._s(_vm.getStatusText(scope.row.co_status)))])]}}])}),_c('el-table-column',{attrs:{"prop":"co_remark","label":"备注"}}),_c('el-table-column',{attrs:{"label":"审核时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("ts2Date")(scope.row.co_verifytime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"提交时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("ts2Date")(scope.row.co_addtime))+" ")]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pager.page,"page-size":_vm.pager.limit,"layout":"total, prev, pager, next,jumper","total":_vm.pager.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.pager, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.pager, "page", $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }