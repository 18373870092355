<!-- 提现记录 -->
<template>
    <div class="w100">
        <header>
        <!-- <el-form :inline="true" :model="filterObj" class="demo-form-inline">
                <el-form-item label="活动区域">
                    <el-select v-model="filterObj" placeholder="活动区域">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> <el-form-item>
                    <el-button type="primary" @click="filter">查询</el-button>
                                                                            </el-form-item></el-form> -->
        </header>
        <el-table border :data="list" style="width: 90%">
            <el-table-column prop="co_no" label="订单号">
            </el-table-column>
            <el-table-column prop="u_ali_account" label="收款账号">
            </el-table-column>
            <el-table-column prop="u_ali_realname" label="收款人姓名">
            </el-table-column>
            <el-table-column prop="co_price" label="提现金额">
            </el-table-column>

            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :style="{
                        color:
                            scope.row.co_status == 0
                                ? '#0882ea'
                                : scope.row.co_status == 1
                                    ? '#04d932'
                                    : '#ff3e13',
                    }"> {{ getStatusText(scope.row.co_status) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="co_remark" label="备注">
            </el-table-column>
            <el-table-column label="审核时间">
                <template slot-scope="scope">
                    {{ scope.row.co_verifytime | ts2Date() }}
                </template>
            </el-table-column>
            <el-table-column label="提交时间">
                <template slot-scope="scope">
                    {{ scope.row.co_addtime | ts2Date() }}
                </template>
            </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pager.page"
            :page-size="pager.limit" layout="total, prev, pager, next,jumper" :total="pager.total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [], filterObj: {},
            pager: {
                page: 1,
                limit: 7,
                total: 0
            }
        };
    },

    components: {},
    filters: {
        ts2Date: (timestamp) => {
            if (timestamp == 0) {
                return "";
            }
            let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                Y = date.getFullYear() + "-",
                M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-",
                D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
                h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":",
                m =
                    (date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes()) + ":",
                s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
    },

    mounted() {
        this.getList()
    },

    methods: {
        filter() {

        },
        handleSizeChange(val) {
            this.pager.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            this.pager.page = val
            this.getList()

        },

        async getList() {
            let res = await this.$axios
                .get("/api/account/getCashOrderList", {
                    params: { ...this.filterObj, ...this.pager },
                })
            this.list = res.data.rows
            this.pager.total = res.data.total


        },
        getStatusText(status) {

            let text = ''
            switch (status) {
                case 0:
                    text = "审核中"
                    break
                case 1:
                    text = "已通过"
                    break
                case 2:
                    text = '已驳回'

                    break
                default:
                    text = '失败'

            }
            return text

        },
    }
}

</script>
<style scoped></style>